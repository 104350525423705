import * as Ariakit from '@ariakit/react'
import * as React from 'react'

import { usePrevious } from '../hooks/use-previous'
import { Input, InputComponentProps } from './input'
import { Tooltip, TooltipColor, TooltipProps, TooltipTrigger } from './tooltip'

interface TooltipSettingsProps
  extends Pick<TooltipProps, 'gutter' | 'isPointerEventsEnabled'>,
    Pick<Ariakit.TooltipStoreProps, 'placement'> {
  color?: keyof typeof TooltipColor
}

interface TooltipInputProps extends InputComponentProps {
  /**
   * Settings for Tooltip component and useTooltipStore
   */
  tooltipSettings?: TooltipSettingsProps
}

function TooltipInputComponent(
  { tooltipSettings, name, children, ...props }: React.PropsWithChildren<TooltipInputProps>,
  ref: React.ForwardedRef<HTMLInputElement>
) {
  const { color, gutter, placement = 'bottom', isPointerEventsEnabled } = tooltipSettings ?? {}
  const prevMessage = usePrevious(children, true)

  const [isFocused, setIsFocused] = React.useState(false)
  const handleFocus = React.useRef(() => setIsFocused(true))
  const handleBlur = React.useRef(() => setIsFocused(false))

  const input = <Input {...props} ref={ref} name={name} />
  if (!children && !prevMessage) return input

  return (
    <Ariakit.TooltipProvider placement={placement} open={isFocused && !!children}>
      <TooltipTrigger
        state={undefined}
        hideOnBlur
        showOnHover={false}
        render={input}
        onFocus={handleFocus.current}
        onBlur={handleBlur.current}
      />
      <Tooltip
        state={undefined}
        isPointerEventsEnabled={isPointerEventsEnabled}
        id={name}
        color={color}
        gutter={gutter}
        className="px-2.5"
        hideOnHoverOutside={false}
        data-testid={name?.length ? `${name}-tooltip` : undefined}
      >
        {children ?? prevMessage}
      </Tooltip>
    </Ariakit.TooltipProvider>
  )
}

export const TooltipInput = React.forwardRef(TooltipInputComponent)
