import * as React from 'react'

export const CHART_CONTEXT_MENU_ID = 'chartContextMenu'

export function hasChartContextMenuGainedFocus(blurEvent: React.FocusEvent<HTMLDivElement> | undefined) {
  const chartContextMenu = document.getElementById(CHART_CONTEXT_MENU_ID)
  if (chartContextMenu && blurEvent?.relatedTarget) {
    return chartContextMenu === blurEvent.relatedTarget || chartContextMenu.contains(blurEvent?.relatedTarget)
  }

  return false
}
