import React from 'react'

import { Button } from '../button'
import { Icon } from '../icon'

const imgFromUrlToBase64 = (url: string) =>
  fetch(url).then(async (response) => URL.createObjectURL(await response.blob()))

export interface DownloadImageButtonProps {
  /**
   * The URL of the image to download. If not provided, button will be disabled
   */
  downloadUrl?: string | null

  /**
   * Optional base64 encoded image data. If provided, this will be used instead of downloadUrl
   */
  base64ImageData?: string | null
}

export function DownloadImageButton({ downloadUrl, base64ImageData: dataUrl }: DownloadImageButtonProps) {
  const fileName = React.useMemo(() => {
    const name = downloadUrl?.split(/[/?#]/).pop() ?? 'image'
    return name.includes('.') ? name : `${name}.png`
  }, [downloadUrl])

  const [imageUri, setImageUri] = React.useState<string | null>(null)
  React.useEffect(() => {
    const val = dataUrl || downloadUrl
    if (!val) return
    imgFromUrlToBase64(val)
      .then(setImageUri)
      .catch(() => {})
  }, [dataUrl, downloadUrl])

  return (
    imageUri && (
      <Button
        as="a"
        href={imageUri}
        download={fileName}
        onClick={() => {
          window.gtag?.('event', 'click', { event_category: 'social-share-image-download' })
        }}
        size="small"
        contentClass="flex items-center"
        leftContent={<Icon name="import" width={16} />}
      >
        Download
      </Button>
    )
  )
}
