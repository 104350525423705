import classNames from 'classnames'
import * as React from 'react'

import { Button, ButtonRoundingType, ButtonSizeType, ButtonThemeType } from '../button'
import { ButtonGroup, ButtonGroupProps } from '../button-group'
import { SelectItem } from '../select/types'

export type ButtonSwitchItem<ValueType> = Pick<SelectItem<ValueType>, 'value' | 'label' | 'disabled'>

export interface ButtonSwitchProps<ValueType, ItemType extends ButtonSwitchItem<ValueType>> extends ButtonGroupProps {
  /**
   * Label to display above the select
   */
  label?: string

  /**
   * Class names for label
   */
  labelClassNames?: string

  /**
   * Array of items to display as button options
   */
  items: ItemType[]

  /**
   * Currently selected value
   */
  value?: ValueType

  /**
   * Default value when uncontrolled
   */
  defaultValue?: ValueType

  /**
   * Callback when item changes. Called for both controlled and uncontrolled selects
   */
  onChange?: (value: ItemType) => void

  /**
   * If applied styles will be as if the component was enabled but clicking buttons won't do anything
   */
  readonly?: boolean

  /**
   * Optional className for the ButtonGroup wrapper
   */
  className?: string

  /**
   * Set the button theme when it is selected
   */
  activeTheme?: ButtonThemeType

  /**
   * Size of the button
   *
   * @default "regular"
   */
  size?: ButtonSizeType

  /**
   * Data test id
   *
   * @default ""
   */
  'data-testid'?: string

  groupRounding?: ButtonRoundingType
}

export function ButtonSwitch<ValueType, ItemType extends ButtonSwitchItem<ValueType>>(
  props: Omit<ButtonSwitchProps<ValueType, ItemType>, 'children'>
) {
  const {
    items,
    value: controlledValue,
    label,
    defaultValue,
    onChange,
    className = 'flex p-0.5',
    size,
    labelClassNames,
    rounding = 'regular',
    groupRounding = 'regular',
    activeTheme = 'blue',
    readonly = false,
    ...restProps
  } = props
  const [internalValue, setInternalValue] = React.useState<ValueType | undefined>(defaultValue)
  const value = controlledValue !== undefined ? controlledValue : internalValue

  const handleChange = React.useCallback(
    (newValue: ItemType) => {
      setInternalValue(newValue.value)
      onChange?.(newValue)
    },
    [onChange]
  )

  return (
    <div className="inline-flex items-center gap-1">
      <ButtonGroup {...restProps} rounding={groupRounding} className={className} hasDivider={false}>
        {items.map((option, index) => (
          <Button
            key={`button-${index}`}
            className={classNames({ 'pointer-events-none': readonly })}
            data-testid={props['data-testid'] && `${props['data-testid']}-button-${index}`}
            contentClass="flex justify-center"
            size={size}
            type="button"
            rounding={rounding}
            disabled={option.disabled}
            theme={value === option.value ? activeTheme : undefined}
            onClick={readonly ? undefined : () => handleChange(option)}
          >
            {option.label}
          </Button>
        ))}
      </ButtonGroup>
      {label && <span className={classNames('select-none text-2xs', labelClassNames)}>{label}</span>}
    </div>
  )
}
