import { SpecificChartFunctionality } from '../../main/constants/common'
import { ChartConfigChartPaneElement, Instrument, LayoutType, ScaleType, TIMEFRAME, Theme } from '../charts-lib'
import { getParsedCookies } from './cookie'

interface PaneElementProps extends ChartConfigChartPaneElement {
  label?: string
  fromDate?: string
  toDate?: string
  min?: number
  tickers: string[]
}

interface StaticChartConfigProps {
  width?: number
  height?: number
  ticker: string
  timeframe: TIMEFRAME
  paneElements: PaneElementProps[]
  specificChartFunctionality?: SpecificChartFunctionality
  colorsOverride?: Array<string>
  theme?: Theme
  colors?: ReturnType<typeof getCustomColorsFromCookies>
  refreshData?: boolean
}

export function getStaticChartConfig({
  width = 800,
  height = 400,
  ticker,
  timeframe,
  paneElements,
  specificChartFunctionality = SpecificChartFunctionality.default,
  colorsOverride,
  theme,
  colors = getCustomColorsFromCookies(),
  refreshData = false,
}: StaticChartConfigProps) {
  return {
    layout: LayoutType['1h1v'],
    width,
    height,
    barWidth: 1,
    barMargin: 0,
    scrollable: false,
    editable: false,
    specificChartFunctionality,
    colorsOverride,
    theme,
    colors,
    charts: [
      {
        width: 0,
        premarket: false,
        aftermarket: false,
        dateRange: null,
        height,
        timeframe,
        scale: ScaleType.Linear,
        ticker: ticker,
        leftOffset: 0,
        instrument: Instrument.Stock,
        refreshData,
        stretch: true,
        panes: [
          {
            height,
            elements: paneElements,
          },
        ],
      },
    ],
  }
}

export function getCustomColorsFromCookies() {
  try {
    return JSON.parse(getParsedCookies('customColors') as string)
  } catch {
    return undefined
  }
}
