import { ContentType, apiRequest } from '../../main/services/api'
import { mapPerf } from '../maps/services/api'
import mapActionCreators from '../maps/store/action-creators'
import { MapSubtypeId, MapTypeId } from '../maps/types'
import { getMapsRefreshInterval } from '../maps/utils'
import { intervalCallbackOnWindowVisible } from '../shared/documentVisibility'
import { isStockFastRefreshAvailable } from '../shared/isStockFastRefreshAvailable'
import { parseJSONFromScript } from '../shared/parseJSONFromScript'
import { Indices, updateIndices } from './indices-data'

interface RefreshInfo {
  userId: string
  version: number
  interval: number
  hasGeoMaps: boolean
}

const refreshInfo = parseJSONFromScript<RefreshInfo>('refresh-init')

function refreshPage(newDocument: Document) {
  // Replace market stats
  const oldMarketStats = document.getElementsByClassName('market-stats')
  const newMarketStats = newDocument.getElementsByClassName('market-stats')
  if (oldMarketStats.length === newMarketStats.length) {
    Array.from(oldMarketStats).forEach((_, index) => {
      const oldElement = oldMarketStats.item(index)
      const newElement = newMarketStats.item(index)
      if (oldElement && newElement) {
        oldElement.innerHTML = newElement.innerHTML
      }
    })
  }

  replaceSignalTables(newDocument)

  // Replace bottom
  const oldHomepageBottom = document.getElementById('js-homepage_bottom')
  const newHomepageBottom = newDocument.getElementById('js-homepage_bottom')
  if (oldHomepageBottom && newHomepageBottom) {
    oldHomepageBottom.innerHTML = newHomepageBottom.innerHTML
  }

  if (FinvizSettings.hasUserPremium || FinvizSettings.hasRedesignEnabled) {
    void import(/* webpackChunkName: "recent_quotes" */ '../shared/recent_quotes').then(({ RenderRecentQuotes }) => {
      RenderRecentQuotes()
    })
  }

  // Send refresh event to google analytics
  window.gtag?.('event', 'refresh', { event_category: 'page' })
  // Refresh any ads that might be on the page
  if (window.finvizBannersLoaded === true) {
    window.initFinvizBanners?.()
  } else if (typeof window.refreshAds === 'function') {
    window.refreshAds('#js-homepage_bottom [id*=IC_D_]')
  }
}

export function replaceSignalTables(newDocument: Document) {
  // Replace left signals table
  const oldSignalsLeft = document.getElementById('js-signals_1')
  const newSignalsLeft = newDocument.getElementById('js-signals_1')
  if (oldSignalsLeft && newSignalsLeft) {
    oldSignalsLeft.innerHTML = newSignalsLeft.innerHTML
  }

  // Replace right signals table
  const oldSignalsRight = document.getElementById('js-signals_2')
  const newSignalsRight = newDocument.getElementById('js-signals_2')
  if (oldSignalsRight && newSignalsRight) {
    oldSignalsRight.innerHTML = newSignalsRight.innerHTML
  }
}

export async function getRefreshData() {
  const data = await apiRequest<string>(window.location.pathname, {
    headers: {
      'Content-Type': ContentType.Html,
      'X-Is-Ajax-Request': 'true',
      'X-Theme': FinvizSettings.hasDarkTheme ? 'dark' : FinvizSettings.hasRedesignEnabled ? 'light' : 'legacy',
    },
  })

  if (!data) return undefined

  const newDocument = new DOMParser().parseFromString(data, 'text/html')
  const newRefreshInfo = parseJSONFromScript<RefreshInfo>('refresh-init', newDocument)

  if (!refreshInfo || !newRefreshInfo || refreshInfo.userId !== newRefreshInfo.userId) return undefined

  if (refreshInfo.version !== newRefreshInfo.version) {
    window.location.reload()
  }

  return newDocument
}

function initRefresh(interval: number) {
  intervalCallbackOnWindowVisible({ interval }, async function handleRefresh() {
    const newDocument = await getRefreshData()

    if (newDocument) {
      refreshPage(newDocument)
    }
  })
}

enum IndicesPollingIntervalInMs {
  Default = 3000,
  Reduced = 60000,
  Free = 30000,
}

if (refreshInfo?.interval) {
  initRefresh(refreshInfo.interval * 1000)

  function getIndicesRefreshInterval() {
    if (!FinvizSettings.hasUserPremium) {
      return IndicesPollingIntervalInMs.Free
    }
    if (!isStockFastRefreshAvailable(false, false)) {
      return IndicesPollingIntervalInMs.Reduced
    }

    return IndicesPollingIntervalInMs.Default
  }

  intervalCallbackOnWindowVisible({ interval: getIndicesRefreshInterval }, async function handleRefresh() {
    const newIndicesData = await apiRequest<Indices>('/api/indices_data.ashx')

    if (newIndicesData) {
      updateIndices(newIndicesData)
    }
  })

  intervalCallbackOnWindowVisible({ interval: getMapsRefreshInterval }, async function handleRefresh() {
    const perfDataSecReq = mapPerf(MapTypeId.Sector, MapSubtypeId.DayPerf)
    const perfDataGeoReq = refreshInfo.hasGeoMaps ? mapPerf(MapTypeId.World, MapSubtypeId.DayPerf) : null

    const [perfDataSec, perfDataGeo] = await Promise.all([perfDataSecReq, perfDataGeoReq])

    try {
      if (perfDataSec) {
        if (window.FinvizIndexSecMap.version !== perfDataSec.version) {
          window.location.reload()
        }
        mapActionCreators.updatePerfData(window.FinvizIndexSecMap, perfDataSec)
      }

      if (perfDataGeo) {
        if (window.FinvizIndexGeoMap.version !== perfDataGeo.version) {
          window.location.reload()
        }
        mapActionCreators.updatePerfData(window.FinvizIndexGeoMap, perfDataGeo)
      }
    } catch {}
  })
}
