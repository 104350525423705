import {
  MARKET_END,
  MARKET_START,
  MOBILE_VIEW_BREAKPOINT_WIDTH,
  PREMARKET_AFTERMARKET_HOURS,
  SpecificChartFunctionality,
} from './constants/common'
import { captureException, getIsSSr } from './utils/helpers'

export { getSanitizedTicker } from './utils/ticker-sanitizer'

function getIsDstInNy(date: Date) {
  const dayNumber = date.getDate()
  const monthIndex = date.getMonth()
  const dayOfWeek = date.getDay()
  const previousSunday = dayNumber - dayOfWeek
  if (monthIndex < 2 || monthIndex > 10) {
    return false
  }
  if (monthIndex > 2 && monthIndex < 10) {
    return true
  }
  return monthIndex === 2 ? previousSunday >= 8 : previousSunday <= 0
}

class Utils {
  static min<T extends number | number[] = number[]>(...args: T[]): number {
    if (typeof args[0] === 'number') {
      return args[0] < +args[1] ? args[0] : (args[1] as number)
    }
    let min = args[0][0]
    for (const arg of args) {
      for (const el of arg as number[]) {
        if (el < min) {
          min = el
        }
      }
    }
    return min
  }

  static max<T extends number | number[] = number[]>(...args: T[]): number {
    if (typeof args[0] === 'number') {
      return args[0] > +args[1] ? args[0] : (args[1] as number)
    }
    let max = args[0][0]
    for (const arg of args) {
      for (const el of arg as number[]) {
        if (el > max) {
          max = el
        }
      }
    }
    return max
  }

  static minMax(...args: number[][]) {
    let max
    let min = (max = args[0][0] as number)
    for (const arg of args) {
      for (const el of arg) {
        if (el < min) {
          min = el
        }
        if (el > max) {
          max = el
        }
      }
    }
    return [min, max]
  }

  static getScaleRatio() {
    return (typeof window !== 'undefined' && window.devicePixelRatio) || 1
  }

  static setSizeOnCanvasElement({
    canvasElement,
    width,
    height,
    ratio = Utils.getScaleRatio(),
  }: {
    canvasElement: HTMLCanvasElement | null
    width: number
    height: number
    ratio?: number
  }) {
    if (canvasElement) {
      canvasElement.width = width * ratio
      canvasElement.height = height * ratio
      canvasElement.style.width = width + 'px'
      canvasElement.style.height = height + 'px'
    }
  }

  static getQuoteDateTimestampOffsetInSeconds(referenceDate?: Date) {
    const date = referenceDate || new Date()
    const dateAsNY = new Date(
      date.getUTCFullYear(),
      date.getUTCMonth(),
      date.getUTCDate(),
      date.getUTCHours() - 5,
      date.getUTCMinutes(),
      date.getUTCSeconds()
    )
    const isDst = getIsDstInNy(dateAsNY)
    return (isDst ? 4 : 5) * 60 * 60
  }

  static convertLocalToNyTime(referenceDate: Date, addOffset: boolean) {
    const date = new Date(referenceDate)
    const quoteDateTimeOffsetInMs = Utils.getQuoteDateTimestampOffsetInSeconds(date) * 1000
    const localTimeOffsetInMs = date.getTimezoneOffset() * 60 * 1000
    const offsetDirectionCoefficient = addOffset ? 1 : -1
    date.setTime(date.getTime() + (localTimeOffsetInMs - quoteDateTimeOffsetInMs) * offsetDirectionCoefficient)
    return date
  }

  // Keep in sync with the clone in Website repo in shared/isStockFastRefreshAvailable.ts
  static isStockFastRefreshAvailable(hasPremarket = true, hasAftermarket = true, bufferMinutes = 30) {
    const getMinutes = ({ hours, minutes }: { hours: number; minutes: number }) => hours * 60 + minutes

    const date = convertLocalToNyTime(new Date(), true)
    const dayOfWeek = date.getDay()
    const currentDayMinutes = date.getHours() * 60 + date.getMinutes()

    const premarketMinutes = hasPremarket ? PREMARKET_AFTERMARKET_HOURS * 60 : 0
    const aftermarketMinutes = hasAftermarket ? PREMARKET_AFTERMARKET_HOURS * 60 : 0

    const isWeekend = dayOfWeek === 0 || dayOfWeek === 6
    const isOpen = getMinutes(MARKET_START) - premarketMinutes - bufferMinutes <= currentDayMinutes
    const isClosed = getMinutes(MARKET_END) + aftermarketMinutes + bufferMinutes <= currentDayMinutes
    return !isWeekend && isOpen && !isClosed
  }

  static dateFromUnixTimestamp(timestamp: number) {
    /*
     * Passing 0 as a param is needed in order to setUTCSeconds work properly.
     * The function adds seconds to the date instance if it exceeds 60.
     * For more info see the docs https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Date/setUTCSeconds
     * */
    const date = new Date(0)
    date.setUTCSeconds(timestamp)
    return Utils.convertLocalToNyTime(date, true)
  }

  static dateStringFromDate(date: Date) {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
      2,
      '0'
    )}`
  }

  static dateFromDateString(dateString: string) {
    const [yyyy, mm, dd] = dateString.split('-')

    const date = new Date(Number.parseInt(yyyy), Number.parseInt(mm) - 1, Number.parseInt(dd))
    if (Number.isNaN(date.getTime())) {
      captureException(new Error('Invalid Date'), { extra: { date, dateString } })
    }

    return date
  }

  static isMobile(testTouchPoints?: boolean) {
    if (getIsSSr()) return false
    let isMobileOs = false

    // New iOS versions no longer report iPad in user agent
    if (navigator.userAgent.indexOf('Macintosh') > -1) {
      try {
        document.createEvent('TouchEvent')
        isMobileOs = true
      } catch {}
    } else {
      isMobileOs = !!navigator.userAgent.match(
        /\b(Android|webOS|i(?:Phone|Pad|Pod)|BlackBerry|Windows (?:Phone|Mobile))\b/i
      )
    }

    if (testTouchPoints) {
      try {
        return (
          isMobileOs &&
          (window.navigator.msMaxTouchPoints ||
            window.navigator.maxTouchPoints ||
            'ontouchstart' in document.createElement('div'))
        )
      } catch {}
    }

    return isMobileOs
  }

  static isIPad() {
    const matchesIPad = navigator.userAgent.match(/iPad/i)
    const matchesIPhone = navigator.userAgent.match(/iPhone/i)
    const matchesIPod = navigator.userAgent.match(/iPod/i)
    const matchesIOSLike = navigator.userAgent.match(/Mac/) && navigator.maxTouchPoints > 0

    return (matchesIOSLike && !matchesIPhone && !matchesIPod) || matchesIPad
  }

  static getCookie(name: string) {
    const re = new RegExp('(?:(?:^|.*;\\s*)' + name + '\\s*\\=\\s*([^;]*).*$)|^.*$')
    return document.cookie.replace(re, '$1')
  }

  static setCookie(name: string, value: string, expires: Date) {
    let cookie = name + '=' + value + '; expires=' + expires.toUTCString()
    if (document.location.hostname !== 'localhost') {
      cookie += '; domain=.finviz.com'
    }
    document.cookie = cookie
  }
}

export default Utils
export const convertLocalToNyTime = Utils.convertLocalToNyTime
export const dateFromUnixTimestamp = Utils.dateFromUnixTimestamp
export const dateStringFromDate = Utils.dateStringFromDate
export const dateFromDateString = Utils.dateFromDateString

export function getFullscreenStatus(): boolean {
  return (
    document.fullscreenElement ??
    document.webkitFullscreenElement ??
    document.mozFullScreenElement ??
    document.msFullscreenElement ??
    false
  )
}

export function getRequestFullscreen(element: HTMLElement) {
  return (
    element.requestFullscreen ??
    element.webkitRequestFullScreen ??
    element.mozRequestFullScreen ??
    element.msRequestFullscreen
  )
}

export function getExitFullscreen() {
  return (
    document.exitFullscreen ?? document.webkitExitFullscreen ?? document.mozExitFullScreen ?? document.msExitFullscreen
  )
}

export function ready(fn: () => void) {
  if (document.readyState !== 'loading') {
    fn()
    return
  }

  document.addEventListener('DOMContentLoaded', fn)
}

export function getChartsDimensions(chartElementId: string) {
  const PADDING = 2 // Border widths
  const containerElement = document.getElementById(chartElementId)

  const box = containerElement?.getBoundingClientRect()

  return {
    width: Math.max((box?.width ?? 0) - PADDING, 0),
    height: Math.max((box?.height ?? 0) - PADDING, 0),
  }
}

export function getIsMobileScreenSize() {
  return window.innerWidth < MOBILE_VIEW_BREAKPOINT_WIDTH
}

export function getIsMobilePortrait(isMobile?: boolean) {
  if (isMobile ?? Utils.isMobile()) {
    return window.orientation % 180 === 0
  }
  return false
}

export function numberToStringWithUnitsSuffix(value: number) {
  let suffix = ''
  if (value >= 1000000000) {
    value /= 1000000000
    suffix = 'B'
  } else if (value >= 1000000) {
    value /= 1000000
    suffix = 'M'
  } else if (value >= 1000) {
    value /= 1000
    suffix = 'K'
  }

  return value.toFixed(2) + suffix
}

export function isRedesignEnabled() {
  return typeof window !== 'undefined' && window.FinvizSettings?.hasRedesignEnabled
}

export function isRedesignedPage(
  obj: { specificChartFunctionality?: SpecificChartFunctionality | null },
  matchingPages = [
    SpecificChartFunctionality.forexPage,
    SpecificChartFunctionality.futuresPage,
    SpecificChartFunctionality.cryptoPage,
  ]
) {
  const isQuotePage = obj.specificChartFunctionality === SpecificChartFunctionality.quotePage
  const isQuoteFinancials = obj.specificChartFunctionality === SpecificChartFunctionality.quoteFinancials

  return (
    (isRedesignEnabled() && matchingPages.includes(obj.specificChartFunctionality!)) ||
    // Legacy design on quote uses redesigned elements (page header + chart controls)
    isQuotePage ||
    isQuoteFinancials
  )
}

export function getMaxValue(currentValue: number | undefined, newValue: number | undefined) {
  return currentValue && newValue ? Math.max(currentValue, newValue) : (currentValue ?? newValue ?? undefined)
}

export function getMinValue(currentValue: number | undefined, newValue: number | undefined) {
  return currentValue && newValue ? Math.min(currentValue, newValue) : (currentValue ?? newValue ?? undefined)
}
