import { FacebookIcon, FacebookShareButton, TwitterIcon, TwitterShareButton } from 'react-share'

import { Button } from '../button'
import { CopyToClipboard } from '../copy-to-clipboard'
import { Heading } from '../typography'
import { DownloadImageButton } from './DownloadImageButton'

export function SocialShare({
  shareUrl,
  title,
  imageToDownloadURL,
  imageToDownloadBase64Data,
}: {
  shareUrl: string
  title: string
  imageToDownloadURL?: string
  imageToDownloadBase64Data?: string | null
}) {
  return (
    <CopyToClipboard
      label={
        // m-0 class can be removed after removing old css from stock detail
        <Heading level={5} className="m-0">
          Copy and paste link in email, website, or forum
        </Heading>
      }
      text={shareUrl}
      bottomLeftContent={
        <div className="flex space-x-1">
          <FacebookShareButton url={shareUrl}>
            <Button
              as="span"
              size="small"
              contentClass="flex items-center"
              onClick={() => {
                window.gtag?.('event', 'click', { event_category: 'social-share-facebook-button' })
              }}
              leftContent={<FacebookIcon size={16} round={true} className="shrink-0" />}
            >
              Share
            </Button>
          </FacebookShareButton>
          <TwitterShareButton url={shareUrl} via="FINVIZ_com" title={title}>
            <Button
              as="span"
              size="small"
              contentClass="flex items-center"
              onClick={() => {
                window.gtag?.('event', 'click', { event_category: 'social-share-twitter-button' })
              }}
              leftContent={<TwitterIcon size={16} round={true} className="shrink-0" />}
            >
              Twitter
            </Button>
          </TwitterShareButton>
          {imageToDownloadURL && (
            <DownloadImageButton downloadUrl={imageToDownloadURL} base64ImageData={imageToDownloadBase64Data} />
          )}
        </div>
      }
    />
  )
}
