import ReactDOMClient from 'react-dom/client'

import { SpecificChartFunctionality } from '../../main/constants/common'
import {
  ChartElementType,
  ChartLayout,
  Instrument,
  LayoutType,
  Quote,
  ScaleType,
  TIMEFRAME,
  Theme,
} from '../charts-lib'
import { getCustomColorsFromCookies } from '../shared/chartLayoutConfig'
import { parseJSONFromScript } from '../shared/parseJSONFromScript'
import { ready } from '../shared/ready'
import type { Indices } from './indices-data'
import { getData } from './indices-data'
import './market_sentiment'
import './refresh'
import SignalIntervalSwitch from './signals-switch'
import { HP_SIGNAL_SWITCH_RENDER_SELECTOR } from './signals-switch/types'

if (FinvizSettings.hasUserPremium || FinvizSettings.hasRedesignEnabled) {
  void import(/* webpackChunkName: "recent_quotes" */ '../shared/recent_quotes').then(({ RenderRecentQuotes }) => {
    RenderRecentQuotes()
  })
}

function getChartConfig(ticker: string) {
  return {
    layout: LayoutType['1h1v'],
    width: 320,
    height: 190,
    barWidth: 3,
    barMargin: 1,
    scrollable: false,
    editable: false,
    cross: false,
    colors: getCustomColorsFromCookies(),
    specificChartFunctionality: SpecificChartFunctionality.smallIndex,
    theme: FinvizSettings.hasDarkTheme ? Theme.dark : Theme.light,
    charts: [
      {
        width: 0,
        premarket: false,
        aftermarket: false,
        dateRange: null,
        height: 190,
        timeframe: TIMEFRAME.i10,
        scale: ScaleType.Linear,
        ticker: ticker,
        // leftOffset: 0,
        instrument: Instrument.Stock,
        refreshData: false,
        // stretch: true,
        panes: [
          {
            height: 190,
            elements: [
              {
                type: ChartElementType.CandleStick,
                overlays: [],
              },
            ],
          },
        ],
      },
    ],
  }
}

function renderSignalIntervalSwitch() {
  const signalSwitches = document.getElementById(HP_SIGNAL_SWITCH_RENDER_SELECTOR)

  if (signalSwitches) {
    const root = ReactDOMClient.createRoot(signalSwitches)
    root.render(<SignalIntervalSwitch />)
  }
}

ready(function () {
  const indices = parseJSONFromScript<Indices>('js-indices')!
  const root = ReactDOMClient.createRoot(document.getElementById('home_indexes')!)

  Quote.create(getData('^DJI', 'DOW', indices)).save()
  Quote.create(getData('^IXIC', 'NASDAQ', indices)).save()
  Quote.create(getData('^GSPC', 'S&P 500', indices)).save()
  Quote.create(getData('^RUT', 'RUSSELL 2000', indices)).save()

  root.render(
    <>
      <div className="hp_chart">
        <ChartLayout config={getChartConfig('DOW')} />
      </div>
      <div className="hp_chart">
        <ChartLayout config={getChartConfig('NASDAQ')} />
      </div>
      <div className="hp_chart">
        <ChartLayout config={getChartConfig('S&P 500')} />
      </div>
      <div className="hp_chart hidden 3xl:block">
        <ChartLayout config={getChartConfig('RUSSELL 2000')} />
      </div>
    </>
  )

  renderSignalIntervalSwitch()
})
