import * as React from 'react'

import { PartialBy } from '../../types'
import { MenuGroup } from '../MenuGroup'
import { Dropdown, DropdownItem, DropdownProps } from '../dropdown'
import { ObjectHash } from '../types'
import { CHART_CONTEXT_MENU_ID } from './utils'

export interface IContextMenuOption {
  type?: never
  id: string
  label: React.ReactNode
  onClick?: () => void
  options?: ContextMenuItem[]
  gtag?: ObjectHash
}

interface IContextMenuDivider {
  type: 'divider'
  label?: React.ReactNode
}

export type ContextMenuItem = IContextMenuOption | IContextMenuDivider

interface ContextMenuInnerProps extends PartialBy<DropdownProps, 'trigger'> {
  /**
   * Items visible in the menu
   */
  items: ContextMenuItem[]
}

export function ContextMenuInner(
  { trigger, items, onClose, ...props }: ContextMenuInnerProps,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  return (
    <Dropdown
      ref={ref}
      {...props}
      id={CHART_CONTEXT_MENU_ID}
      aria-label="Context Menu"
      trigger={trigger!}
      onClose={onClose}
      // Props are passed to the underlying dropdown menu div, this disables context menu on options
      onContextMenu={(ev) => ev.preventDefault()}
    >
      {items?.map((item, index) => {
        if (item.type === 'divider') {
          return <MenuGroup key={index} label={item.label} />
        }

        if (item?.options !== undefined) {
          return (
            <ContextMenuInner
              items={item.options}
              onClose={onClose}
              trigger={
                <DropdownItem rightContent="caretRight" data-testid={`dropdown-menu-${item.id}`}>
                  {item.label}
                </DropdownItem>
              }
            />
          )
        }

        return (
          <DropdownItem
            key={index}
            onClick={() => {
              item.onClick?.()
              window.gtag?.('event', 'context-menu', Object.assign({ menu_option: item.id }, item.gtag))
            }}
            data-testid={`dropdown-menu-${item.id}`}
          >
            {item.label}
          </DropdownItem>
        )
      })}
    </Dropdown>
  )
}

export const ContextMenu = React.forwardRef(ContextMenuInner)
